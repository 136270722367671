<template lang="pug">
  v-main(v-if='Nosotros' fluid)
    //-PC
    div.d-none.d-md-flex
      v-img( gradient="to top right, rgba(180, 50, 36, 0.45) , transparent" :src='Nosotros.portada' aspect-ratio='3' )
        v-row.mt-12
        v-row.mt-12    
        v-row.mt-12    
        v-row.mt-12 
        v-row.mt-12 
        v-row.mt-12 
        v-row.mt-12 
        v-row.mt-12 
        v-row.mt-12 
        v-row.mt-12.ml-12.mb-12 
          div.rectangulo_titulo.secundario.mr-4.mt-4
          h1(v-html='Nosotros.título').white--text 
        //- v-divider.primario.divider_inicial
    //-Móvil
    div.d-md-none
      v-img( gradient="to top right, rgba(0, 0, 0,.8) ,rgba(69, 64, 64 ,.28)" :src='Nosotros.portada' aspect-ratio=1.9)
        v-row.align-center.justify-center        
          v-col(md=11)
            v-row.mt-12 
            v-row.mt-12 
            v-row.mt-12 
            v-row.align-start.justify-start.mt-5.ml-4 
              div.rectangulo_titulo_movil.primario.mr-4.mt-4
              h1.white--text.my-3 NOSOTROS
    Mision_container
    Vision_container
    v-divider.primario.divider_inicial
    Pilares_clave_container
    v-divider.primario.divider_inicial
    Equipo_container
</template>          
<script>
export default {
  components: {
    Mision_container: ()=>import('./componentes/misión_container'),
    Vision_container: ()=>import('./componentes/visión_container'),
    Pilares_clave_container: ()=>import('./componentes/pilares_clave_container'),
    Equipo_container: ()=>import('./componentes/equipo_container'),
  },
}
</script>
<style lang="sass">
  .rectangulo_titulo
    width: 14px
    height: 73px
  .rectangulo_titulo_movil
    width: 8px
    height: 36px
</style>
<style lang="sass">
  .divider_inicial
    max-height: 10px!important
    height: 9px!important
</style>